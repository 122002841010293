@import '/src/css-variables.scss';

.history-result-card {
  // border: 1px $primary-panel-colour;
  padding: 0.5rem 1rem 0.5rem 0.5rem;

  text-align: left;
  background-color: $secondary-background-colour;

  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.history-result-card-controls {
  float: right;

  & * {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.history-result-card-control {
  font-size: 1.5em;
  cursor: pointer;
}

.history-result-card-label {
  text-transform: capitalize;
}

.control-inactive {
  color: $secondary-disabled-color;

  &:hover {
    color: $secondary-disabled-color;
  }
}
