@import '/src/css-variables.scss';

.secondary-pane-container {
  background-color: $secondary-background-colour;
  width: 25vw;
}

.secondary-pane-button-container {
  display: flex;
  justify-content: right;
}

.secondary-pane-container-content {
  margin-top: -2.5rem;
  width: 25vw;

  h3 {
    color: white;
    margin: 0.5rem;
  }
}

.close-secondary-pane-button {
  background-color: transparent;
  border-color: transparent;
  border-radius: 20%;
  margin: 0.5rem;
  z-index: 1000;
  position: relative;
  top: 0;
  right: 0;
  &:hover {
    background-color: $primary-hover-colour;
  }

  .close-panel-icon {
    color: $primary-icon-colour;
  }
}

.no-display {
  display: none;
}

@keyframes inAnimation {
  0% {
    width: 0;
    // visibility: hidden;
  }
  100% {
    width: 30vw;
    // visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    width: 30vw;
  }
  100% {
    width: 0;
    visibility: hidden;
  }
}
