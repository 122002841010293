@import '/src/css-variables.scss';

.vessel-information-container {
  background-color: $secondary-background-colour;
  text-align: left;
  max-height: 75vh;
  overflow-y: scroll;
  padding: 0.25rem;

  .vessel-information {
    color: white;
    margin-left: 1.5rem;
  }

  .additional-vessel-information {
    margin: 1rem;
    color: white;
  }
  h3 {
    color: white;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
