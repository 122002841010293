@import '/src/css-variables.scss';

.item-toggle-icon {
  color: $primary-icon-colour;

  cursor: pointer;
  &:hover {
    color: white;
  }

  &.toggled {
    color: white;
  }

  &.disabled {
    color: $disabled-color;
    cursor: not-allowed;
  }
}
