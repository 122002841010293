@import '/src/css-variables.scss';

.vessel-dossier-container {
  background-color: $primary-background-colour;
  width: 30vw;
  z-index: 1000;
  height: 100vh;
  position: absolute;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dossier-title {
  display: inline-flex;
  text-align: left;
  width: 100%;
  margin-left: 1rem;

  h3 {
    margin-left: 1rem;
    color: $primary-icon-colour;
  }

  h4 {
    color: white;
  }

  button {
    background-color: transparent;
    border: none;
    height: 2rem;
    margin-top: 1rem;
    position: absolute;
    right: 0;
    margin-right: 1rem;

    &:hover {
      background-color: $primary-hover-colour;
    }
    .close-panel-icon {
      color: $primary-icon-colour;
    }
  }
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    border-bottom: 1px solid $primary-icon-colour;
    margin: 0 1rem 5px;
    padding: 0;
    text-align: left;
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    border-radius: 5px 5px 0 0;
    background: $primary-item-colour;
    border-color: black;

    &:hover {
      background: $primary-hover-colour;
    }

    color: white;
    cursor: pointer;

    &--selected {
      background: $primary-icon-colour;
      border-color: transparent;
      color: black;
      border-radius: 5px 5px 0 0;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      outline: none;

      &:after {
        content: '';
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
    }
  }

  &__tab-panel {
    display: none;
    background-color: $secondary-background-colour;
    margin: 0 1rem;
    height: 100%;

    &--selected {
      display: block;
    }
  }
}

.vessel-dossier-options {
  margin: 2rem;
  display: flex;
  justify-content: right;

  .see-vessel-history-button {
    background-color: $primary-icon-colour;
    border-color: transparent;
    border-radius: 10px;
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 2.5rem;

    .see-history-icon {
      padding-top: 0.6rem;
      padding-right: 0.5rem;
      margin: 0;
    }
    p {
      margin: 0;
      padding-top: 0.5rem;
    }

    &:hover {
      background-color: $primary-hover-colour;
    }
  }
}

@keyframes inAnimation {
  0% {
    width: 0;
    // visibility: hidden;
  }
  100% {
    width: 30vw;
    // visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    width: 30vw;
  }
  100% {
    width: 0;
    visibility: hidden;
  }
}
