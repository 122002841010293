@import '/src/css-variables.scss';

.all-vessels-container {
  height: 85vh;
  overflow-y: scroll;
  background-color: $primary-background-colour;
  border-radius: 10px;

  .all-vessels-buttons {
    column-gap: 0.5rem;
    margin: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;

    .all-vessels-delete {
      background-color: red;
      &:hover {
        background-color: rgba(255, 0, 0, 0.5);
        color: white;
      }
    }
  }
}
