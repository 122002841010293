@import '/src/css-variables.scss';

.rectangle-button {
  background-color: $primary-icon-colour;
  border-color: transparent;
  border-radius: 10px;
  height: fit-content;
  width: fit-content;
  font-size: large;
  display: flex;
  flex-direction: row;
  align-items: center;

  .rectangle-button-icon {
    margin-right: 1rem;
  }

  &:hover {
    background-color: $primary-hover-colour;
  }
}
