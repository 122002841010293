@import '/src/css-variables.scss';

.accordion {
  max-width: 600px;
  margin: 2rem auto;
}

.accordion-item {
  padding-bottom: 0.5rem;
}

.accordion-icon {
  padding-right: 10px;
}

.accordion-title {
  display: grid;
  grid-template-columns: 90% 10%;
  background: $primary-item-colour;

  &:hover {
    background-color: $primary-hover-colour;
  }
}

.accordion-title,
.accordion-content {
  padding: 1rem;
  padding-top: 1rem;
}

.accordion-content {
  background-color: $primary-panel-colour;
}
