@import '/src/css-variables.scss';

.history-ais-data-gaps {
  background-color: $secondary-background-colour;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}

.history-ais-data-gap-result {
  background-color: $primary-background-colour;
  margin-bottom: 10px;
  padding: 0.5rem 2.5rem 0.5rem 0.5rem;
  cursor: pointer;

  &.selected {
    background-color: $primary-selected-colour;
  }
}

.history-ais-data-gap-results {
  height: 20rem;
  overflow-y: auto;
  resize: vertical;
}

.history-ais-data-gaps-stats,
.history-ais-data-gap-results,
.history-ais-data-gaps-input-area,
.history-ais-data-gaps-message.gap-length-label,
.history-ais-data-gap-clear-selection {
  margin-top: 10px;
}

.ais-data-gaps-popup {
  text-align: left;
}

.gap-length-label {
  margin-left: 10px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}
