@import '/src/css-variables.scss';

.vessel-information-label {
  height: 2rem;

  .vessel-information-label-title {
    text-align: right;
    padding-right: 2rem;
    width: 11rem;
  }

  .vessel-information-label-data {
    color: $primary-icon-colour;
    width: 13rem;
  }
  tr {
    display: block ruby;
    width: 100%;
  }
}
