@import '/src/css-variables.scss';

.cms-container {
  display: grid;
  grid-template-columns: 15rem auto;
  color: white;

  .cms-managment-panels {
    background-color: $secondary-background-colour;
  }
}
