@import '/src/css-variables.scss';

.feedback-bar-container {
  height: 2rem;
  width: 100%;
  background-color: $primary-hover-colour;
  color: white;
  z-index: 1000;
  position: fixed;
  top: -2.5rem;
  font-size: 18px;
  font-weight: bold;
  animation: feedback-bar-slide 10s linear 2s 1 normal forwards;
  animation-name: feedback-bar-slide;
  animation-duration: 10s;
  padding-top: 0.5rem;

  .feedback-bar-container-message {
    padding-top: 1rem;
  }
}

@keyframes feedback-bar-slide {
  0% {
    top: -2.5rem;
  }
  10% {
    top: 0;
  }
  90% {
    top: 0;
  }
  100% {
    top: -2.5rem;
  }
}
