@import '/src/css-variables.scss';

.side-menu {
  background-color: $primary-background-colour;
  height: 100vh;
  width: 3rem;

  .side-menu-title {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
}

.side-menu-open {
  animation: side-menu-slide-open 0.2s linear 0s 1 normal forwards;
}

.side-menu-closed {
  animation: side-menu-slide-close 0.2s linear 0s 1 normal forwards;
  // width: 3rem;
}

@keyframes side-menu-slide-open {
  0% {
    width: 3rem;
  }
  100% {
    width: 16rem;
  }
}

@keyframes side-menu-slide-close {
  0% {
    width: 16rem;
  }

  100% {
    width: 3rem;
  }
}

.side-menu-options {
  .side-menu-expanded-header {
    display: grid;
    grid-template-columns: 3rem auto;
    width: 16rem;
    padding-left: 0.25rem;
  }

  .highlight-menu-item {
    width: 2.8rem;
    // margin-left: 0.2rem;
    padding-left: 0.2rem;
    &:hover {
      background-color: $primary-hover-colour;
    }
  }
}

.side-menu-item {
  display: grid;
  grid-template-columns: 6fr 1.5fr;
  padding: 0.5rem;

  &:hover {
    background-color: $primary-hover-colour;
  }

  .side-menu-item-label {
    display: flex;
  }

  .side-menu-item-toggle {
    padding-top: 0.5rem;
  }
}

.side-menu-item:hover .side-menu-item-name {
  color: white;
}

.side-menu-icon {
  padding: 0.5rem 0.5rem 0.5rem 0;
  color: $primary-icon-colour;
}

.menu-button {
  display: block;
  background-color: transparent;
  border: none;
  padding: 0.5rem;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $primary-hover-colour;
  }
}
