@import '/src/css-variables.scss';

.upload-new-vessel-modal {
  width: 30vw;
  height: 35vh;
  //   input {
  //     border: $primary-hover-colour solid 1px;
  //   }

  .upload-vessels-modal-content {
    padding: 1rem;
  }

  .upload-vessels-form {
    display: grid;
    position: relative;
    grid-template-rows: auto auto;
    row-gap: 0.5rem;
    text-align: center;
    input {
      border: solid 1px $primary-hover-colour;
    }

    .confirm-upload-container {
      text-align: right;
    }

    .confirm-vessel-upload-button {
      background-color: green;
      height: 2rem;
      width: 8rem;
      border-color: transparent;
      color: white;
      border-radius: 10px;
      &:hover {
        background-color: rgba(0, 128, 0, 0.5);
      }
    }
  }
}
