@import '/src/css-variables.scss';

.search-bar-container {
  position: fixed;
  top: 0;
  right: 0;
  margin-top: 1.75rem;
  margin-right: 7rem;
  border: solid;
  border-color: transparent;
  border-radius: 25px;
  width: 18rem;
  height: 2.5rem;
  display: flex;
  justify-content: left;
  background-color: white;

  &:focus-within {
    border-color: $primary-hover-colour;
  }

  .search-bar-conainer-icon {
    color: $primary-icon-colour;
    padding-left: 0.5rem;
    padding-top: 0.25rem;
  }
}

.search-bar {
  width: 12rem;
  height: 2rem;
  border: none;
  padding-top: 0.25rem;
  padding-left: 1.5rem;
  font-size: larger;

  &:focus {
    outline: none;
  }
}
