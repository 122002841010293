.toggle-switch-container {
  display: grid;
  grid-template-columns: auto auto;
  width: fit-content;
}

.switch-container {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.toggle-switch-label {
  color: black;
  font-size: large;
  margin-left: 1rem;
}
