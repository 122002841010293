@import '/src/css-variables.scss';

.create-company-modal {
  width: 30vw;
  height: 35vh;
}

.create-company-form {
  margin: 2rem;
  position: relative;

  .create-company-form-panel {
    display: flex;
    justify-content: center;
  }

  .company-name-label {
    margin-right: 1rem;
  }

  .company-error-message {
    color: red;
    font-size: small;
    margin-bottom: 2rem;
  }

  .company-error-field {
    border-color: red;
  }

  .company-name-input {
    width: 20rem;
  }

  .create-company-submit-button {
    margin-top: 2rem;
    background-color: green;
    color: white;
    border-color: transparent;
    border-radius: 10px;
    width: 4rem;
    height: 2rem;

    &:hover {
      background-color: rgba(0, 128, 0, 0.589);
    }
  }
}
