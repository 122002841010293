//#################### COLOURS ##########################

$primary-icon-colour: #2abbaf;
$primary-hover-colour: #137cab;
$primary-selected-colour: #0071a5;
$primary-item-colour: #1b5066;
$primary-background-colour: #231f20;
$secondary-background-colour: #383334;
$error-colour: #be0808;
$disabled-color: #acacac;
$secondary-disabled-color: #858585;
$primary-panel-colour: #acacac;

//#################### Z INDEXES #######################
$top-layer: 10000;
$menu-layer: 9000;
