@import '/src/css-variables.scss';

.vessel-management-container {
  text-align: center;
  color: white;
  background-color: $secondary-background-colour;

  .vessel-management-lists {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 2rem;
    padding: 1rem;
  }
}
