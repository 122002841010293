@import '/src/css-variables.scss';

.user-menu {
  width: 13rem;
  height: fit-content;
  background-color: lightgray;
  position: absolute;
  left: -9rem;
  border-radius: 5%;
  animation: user-menu-fade 0.4s;

  a {
    color: black;
    text-decoration: none;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      cursor: default;
      &:hover {
        background-color: $primary-hover-colour;
      }
    }
  }
}

@keyframes user-menu-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.user-menu-list-element {
  padding: 0.5rem;
  display: flex;
  justify-content: left;
  margin-left: 0.2rem;
  font-size: 18px;
  font-weight: bold;

  .user-menu-list-element-icon {
    padding: 2px;
    margin-right: 0.5rem;
  }
}

hr {
  width: 80%;
}
