@import '/src/css-variables.scss';

.layer-list-item {
  width: 100%;
  display: flex;
  margin-bottom: 5px;
}

.layer-list-item-label {
  width: 100%;
}

.layer-list-item-toggle {
  float: right;
}

.layer-list {
  background-color: $secondary-background-colour;
  padding-left: 0.5rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
