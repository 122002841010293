@import '/src/css-variables.scss';

.simple-modal {
  background-color: rgb(238, 238, 238);
  height: fit-content;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .modal-header {
    display: flex;
    height: 2.5rem;
    background: $primary-item-colour;
    margin-bottom: 2rem;
    h2 {
      background: transparent;
      color: white;
      text-align: center;
      margin-top: 0;
      width: 100%;
      height: 100%;
    }

    .close-modal {
      background: transparent;
      border: none;

      &:hover > .close-modal-icon {
        color: $primary-icon-colour;
      }

      .close-modal-icon {
        color: white;
      }
    }
  }
}

.simple-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  z-index: $top-layer;
}
