@import '/src/css-variables.scss';

.user-menu-container {
  position: fixed;
  top: 0;
  right: 0;
  margin: 1rem;

  .user-menu-open {
    background-color: $primary-hover-colour;
  }

  .user-menu-closed {
    background-color: $primary-icon-colour;
  }

  .user-button {
    border-color: transparent;
    border-radius: 12px;

    &:hover {
      background-color: $primary-hover-colour;
    }

    .user-button-menu-open {
      background-color: $primary-hover-colour;
    }

    .user-button-icon {
      padding: 0.5rem;
    }
  }
}
