.delete-modal-content {
  margin: 1rem;
  width: 40vw;
  height: 45vh;
}

.delete-modal-caution-triangle {
  color: orangered;
}

.delete-confirm {
  display: inline-grid;
}

.delete-modal-buttons {
  margin-top: 1rem;
  .delete-modal-button {
    height: 2rem;
    width: 6rem;
    border-radius: 10px;
    border-color: transparent;
    background-color: red;
    color: white;

    &:hover {
      background-color: rgba(252, 101, 101, 0.486);
      color: black;
    }
  }
}

.delete-modal-success-panel {
  width: 5rem;
  margin: auto;
  display: grid;
  grid-template-rows: 90% 10%;
  row-gap: 2rem;
  .success-circle {
    color: green;
  }
}

.delete-modal-loading-panel {
  width: 5rem;
  margin: auto;
  display: grid;
  grid-template-rows: 90% 10%;
  row-gap: 2rem;
}
