@import '/src/css-variables.scss';

.history-panel-results-view {
  text-align: left;
  margin: 1rem;
  height: 100%;
  flex-direction: column;
  display: flex;
}

.history-panel-results-export {
  width: 100%;
}

.history-panel-results-export,
.history-results-export-button {
  margin-bottom: 10px;
  display: table-cell;
  color: white;
  border-radius: 0px;
  background-color: green;
}

.history-panel-back {
  cursor: pointer;
}

.history-panel-results-label {
  margin-left: 10px;
}

.history-panel-results-stats {
  margin: 20px 0px 20px 0px;

  & > * {
    margin-bottom: 5px;
  }
}

.history-panel-results-count,
.history-panel-results-date {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.history-panel-results {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.history-panel-results-sections {
  flex-grow: 1;
  overflow-y: auto;
}
