@import '/src/css-variables.scss';

.options-menu-container {
  background-color: $primary-background-colour;
  height: 100vh;
  color: $primary-icon-colour;
  font-size: 1.25rem;
  width: 15rem;

  h4 {
    margin: 0;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    color: white;
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding-top: 0.5rem;
    padding-left: 0;
    list-style: none;

    li {
      height: 4rem;
      padding-left: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $primary-icon-colour;
      &:hover {
        background-color: $primary-hover-colour;
        color: white;
      }

      .cms-options-icon {
        margin-right: 1.5rem;
      }
    }
  }

  .admin-selected {
    background-color: $primary-hover-colour;
    color: white;
    li {
      color: white !important;
    }
  }
}
