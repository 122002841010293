@import '/src/css-variables.scss';

.side-menu-item-selected {
  background-color: $primary-hover-colour;
  color: white;
}

.side-menu-item-selected .side-menu-item-name {
  color: white !important;
}

.side-menu-item-name {
  color: $primary-icon-colour;
  font-size: 16px;
  display: flex;
  justify-content: left;
  padding-top: 0.5rem;
  margin-left: 1rem;
}

.side-menu-icon {
  width: 2rem;
}
