@import '/src/css-variables.scss';

.search-filter-container {
  display: grid;
  padding: 0.5rem;
  grid-template-columns: 5fr 0.5fr;
  margin: 1rem;
  background-color: white;
  border-radius: 10px;
  height: 2.75rem;
  border: solid;
  border-width: 3px;

  &:focus-within {
    border-color: $primary-hover-colour !important;
  }

  .search-filter-delete-button {
    background-color: white;
    border: none;
    // height: 1.2rem;
    &:hover {
      background-color: $primary-hover-colour;
    }
  }

  .search-filter {
    width: 95%;
    height: 1.5rem;
    border: none;
    margin-left: 0.5rem;
    &:focus {
      outline: none;
    }
    // margin-top: 0.2rem;
  }
}
