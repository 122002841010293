@import '/src/css-variables.scss';

.company-info-container {
  background-color: $secondary-background-colour;
  padding-right: 1rem;

  .company-info-container-header {
    padding: 1rem;
    display: grid;
    grid-template-columns: auto auto;
  }

  h2 {
    margin: 0;
    color: white;
  }

  .company-view-container {
    margin: 1rem;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1rem;

    .features-container {
      background-color: $primary-background-colour;
      color: white;
      border-radius: 10px;
    }

    .users-container {
      background-color: $primary-background-colour;
      color: white;
      border-radius: 10px;
      text-align: center;
      .users-container-buttons {
        margin: 1rem;
        text-align: center;
        display: flex;
        justify-content: center;
        column-gap: 0.5rem;
        .users-delete {
          background-color: red;
          &:hover {
            background-color: rgba(255, 0, 0, 0.5);
            color: white;
          }
        }
      }
    }
  }
}
