@import '/src/css-variables.scss';

.simple-vessel-table {
  width: 100%;
  border: none;
  border-spacing: 0;
  padding: 1rem;

  thead th {
    font-size: small;
    background-color: #1b5066;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #1b5066;
    color: white;
    font-size: smaller;
  }

  tbody td {
    border: none;
    padding: none;
    text-align: center;
    width: fit-content;
  }

  tbody tr:hover {
    background-color: $primary-hover-colour !important;
  }

  tbody tr:nth-child(odd) {
    background-color: lightgray;
    color: black;
  }

  tbody tr:nth-child(even) {
    background-color: rgb(138, 138, 138);
    color: black;
  }

  // table th {
  //   padding-top: 12px;
  //   padding-bottom: 12px;
  //   text-align: center;
  //   background-color: #1b5066;
  //   color: white;
  //   font-size: smaller;
  // }
}
