@import '/src/css-variables.scss';

.alert-panel-container {
  color: white;
  height: 90vh;
  background-color: $secondary-background-colour;
}

.alert-panel-container-content {
  overflow-y: scroll;
  height: 95%;
}

.alerts-panel-container-current-alerts {
  margin: 1rem;
  text-align: left;
}

.alert-panel-container-current-alerts-table {
  display: flex;
  justify-content: center;
}

.alert-panel-loading-spinner {
  display: flex;
  justify-content: center;
  margin-top: 30vh;
}

.alert-panel-error {
  display: flex;
  justify-content: center;
  margin-top: 50%;

  ul {
    list-style: none;
    padding-left: 0;
  }

  .alert-panel-error-icon {
    color: $error-colour;
  }
}
