@import '/src/css-variables.scss';

.heatmap-container {
  background-color: $secondary-background-colour;
  padding-left: 0.5rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.heatmap-key {
  height: 40px;
  width: 100%;
  border-radius: 10px;
  border: 2px solid $primary-background-colour;
}

.heatmap-key-label {
  text-align: center;
  margin-top: 10px;
}
