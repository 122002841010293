@import '/src/css-variables.scss';

.company-management-container {
  background-color: $secondary-background-colour;
  text-align: center;
  h1 {
    color: white;
  }
}
.company-crud-buttons {
  position: absolute;
  right: 0;
  margin-right: 1rem;
  display: grid;
  grid-template-columns: auto auto;
}

.company-management-panel {
  margin-top: 5rem;
}

.checkbox-header {
  background-color: $primary-item-colour;
}

.selected-row {
  background-color: $primary-selected-colour !important;
}

.remove-company {
  background: rgb(212, 0, 0);
  margin-right: 2rem;

  &:hover {
    background: rgb(255, 100, 100);
  }
}
