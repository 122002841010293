@import '/src/css-variables.scss';

.App {
  text-align: center;
}
.menu-container {
  height: 100vh;
  position: absolute;
  z-index: $menu-layer;
}

.app-vessel-dossier-container {
  height: 100vh;
  position: absolute;
  z-index: $top-layer;
}

.app-container {
  width: 100vw;
  height: 100vh;
}

.company-logo {
  position: fixed;
  margin-top: 1rem;
  top: 0;
  left: 50vw;
  z-index: 100;
  width: 4rem;
  height: 4rem;
}

.button:disabled,
button[disabled] {
  background-color: $disabled-color !important;
  color: white !important;
  cursor: not-allowed;
}

// change default styles for mapbox control buttons
.mapboxgl-ctrl-group button {
  background-color: $primary-icon-colour !important;
  width: 36px !important;
  height: 36px !important;
}

.mapboxgl-ctrl-group button:hover {
  background-color: $primary-hover-colour !important;
}

.mapboxgl-ctrl-group button + button {
  border-top: 1px solid black !important;
}

.mapboxgl-popup-content-header {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.login-container {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-image: url('assets/background-login-page.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
  background-color: #131111;
}
