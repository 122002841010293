@import '/src/css-variables.scss';

.current-alerts-filter-container {
  display: grid;
  padding: 0.5rem;
  grid-template-columns: 5fr 0.5fr;
  margin: 1rem 0;
  background-color: white;
  border-radius: 10px;
  height: 1.5rem;
  border: solid;
  border-width: 3px;

  &:focus-within {
    border-color: $primary-hover-colour !important;
  }

  .current-alerts-filter-delete-button {
    background-color: white;
    border: none;
    // height: 1.2rem;
    &:hover {
      background-color: $primary-hover-colour;
    }
  }

  .current-alerts-filter {
    width: 95%;
    height: 1.5rem;
    border: none;
    margin-left: 0.5rem;
    &:focus {
      outline: none;
    }
    // margin-top: 0.2rem;
  }
}

.current-alerts-alerting-table {
  display: flex;
  justify-content: center;
}
